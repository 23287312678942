import React from 'react'
import Layout from '../../components/Layout'
import { Image, Text, View } from 'react-native'
import { colours } from '../../styles/constants'
import getSuperOptions from '../../data/superOptions'
import { CheckField } from '../../components/Inputs/Fields'
import Circle from '../../components/Circle'
import storage from '../../helpers/storage'
import api from '../../helpers/boost-client-js-library/api'
import LoadingIndicator from '../../components/LoadingIndicator'
import Notification from '../../components/Notification'
import checkRole from '../../helpers/checkRole'
import reSuperOptions from '../../helpers/reSuperOptions'
import { TEMP_MAX_ITEMS } from '../../helpers/offline'
import DashboardBase, { List, Section, styles, SubSection, SubSubSection, Wrapper } from './DashboardBase'
import BreakpointWatcher from '../../helpers/BreakpointWatcher'
import lineHeight from '../../helpers/lineHeight'

class OffersNumber extends React.Component {
  render () {
    const { children, exceedsMediumBreakpoint, ...rest } = this.props

    const _style = {
      fontSize: 120,
      lineHeight: lineHeight(100),
      fontFamily: 'greycliff-bold',
      color: colours.green,
      textAlign: 'center',
      marginBottom: 5,
      ...(exceedsMediumBreakpoint ? { } : { width: '100%' })
    }
    return <Text style={_style} {...rest}>{children}</Text>
  }
}

const DashboardPane = ({ children, style = {}, ...rest }) => {
  const _style = {
    ...style
  }
  return <View style={_style} {...rest}>{children}</View>
}

const PaneHero = ({ children, style = {}, ...rest }) => {
  const _style = {
    height: 180,
    alignContent: 'flex-start',
    paddingTop: 25,
    ...style
  }
  return <View style={_style} {...rest}>{children}</View>
}

class StatusSection extends React.Component {
  render () {
    const { children, ...rest } = this.props
    return <View style={{ marginBottom: 30 }} {...rest}>
      {children}
    </View>
  }
}

const textStatusStyle = (bold, exceedsMediumBreakpoint) => {
  return {
    fontSize: exceedsMediumBreakpoint ? 14 : 16,
    textAlign: exceedsMediumBreakpoint ? 'left' : 'center',
    fontFamily: bold ? 'greycliff-bold' : 'greycliff',
    marginBottom: 5
  }
}

class StatusText extends React.Component {
  render () {
    const { children, exceedsMediumBreakpoint, bold, style, ...rest } = this.props

    return <Text style={[textStatusStyle(bold, exceedsMediumBreakpoint), style]} {...rest}>
      {children}
    </Text>
  }
}

class StatusSubText extends React.Component {
  render () {
    const { children, exceedsMediumBreakpoint, ...rest } = this.props

    const subTextStyle = [styles.text]

    subTextStyle.push({
      marginTop: -3,
      display: exceedsMediumBreakpoint ? 'block' : 'inline',
      textAlign: 'center'
    })

    return <Text style={subTextStyle} {...rest}>{children}</Text>
  }
}

class OffersText extends React.Component {
  render () {
    const { numOffers, exceedsMediumBreakpoint, exceedsLargeBreakpoint, ...rest } = this.props

    const _style = {
      fontFamily: 'greycliff-bold',
      fontSize: exceedsMediumBreakpoint ? 14 : 18,
      // marginBottom: exceedsLargeBreakpoint ? 40 : 15,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%'
    }

    if (exceedsMediumBreakpoint) {
      _style.flexDirection = 'column'
    }

    return <React.Fragment>
      <StatusText bold style={_style} exceedsMediumBreakpoint={exceedsMediumBreakpoint} {...rest}>Offer{Number(numOffers) > 1 && 's'}</StatusText>
      <StatusSubText exceedsMediumBreakpoint={exceedsMediumBreakpoint}>enabled</StatusSubText>
    </React.Fragment>
  }
}

class SubHeaderLink extends React.Component {
  render () {
    const { children, exceedsMediumBreakpoint, style, ...rest } = this.props

    const _style = {
      fontFamily: 'greycliff-bold',
      fontSize: exceedsMediumBreakpoint ? 14 : 16,
      textDecorationLine: 'underline',
      marginBottom: 15,
      textAlign: 'center',
      width: '100%'
    }

    return <Text style={[_style, style]} {...rest}>{children}</Text>
  }
}

class InnerCircleWrap extends React.Component {
  render () {
    const { children, exceedsMediumBreakpoint, ...rest } = this.props
    const _style = {
      flexDirection: exceedsMediumBreakpoint ? 'row' : 'column',
      marginTop: -25,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
    return <View style={_style} {...rest}>
      {children}
    </View>
  }
}

const reportIconStyle = {
  width: 165,
  height: 96,
  marginTop: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 9
}

export default class Super extends DashboardBase {
  state = {
    tier: '',
    superOptions: [],
    rollout: [
      {
        label: 'Tick box once complete',
        value: 'customiseLogo',
        link: () => this.props.history.push('/profile/customisation'),
        linkText: 'Customise Logo'
      },
      {
        label: 'Tick box once complete',
        value: 'offerManagement',
        link: () => this.props.history.push('/profile/offer-management'),
        linkText: 'Offer Management'
      },
      {
        label: 'Tick box once complete',
        value: 'reviewRollOutPack',
        link: () => { window.open('https://www.myboost.co.nz/guides/docs/default-source/How-To-Guides/boost-roll-out-pack') },
        linkText: 'Review Roll Out Pack'
      },
      {
        label: 'Tick box once complete',
        value: 'userManagement',
        link: () => this.props.history.push('/profile/user-management-and-ordering'),
        linkText: 'User Management'
      }
    ],
    resources: [
      {
        label: 'Allocate Licenses',
        value: 'allocateLicenses',
        link: () => this.props.history.push('/profile/user-management-and-ordering'),
        linkText: 'Manage users'
      },
      {
        label: 'Emails sent to staff',
        value: 'emailsToStaff',
        link: () => this.props.history.push('/profile/resources'),
        linkText: 'View resources'
      },
      {
        label: 'Collateral ordered',
        value: 'collateralOrdered',
        link: () => this.props.history.push('/profile/resources'),
        linkText: 'View resources'
      },
      {
        label: 'Intranet text published',
        value: 'intranetTextPublished',
        link: () => this.props.history.push('/profile/resources'),
        linkText: 'View resources'
      },
      {
        label: 'FAQs published',
        value: 'faqsPublished',
        link: () => this.props.history.push('/profile/resources'),
        linkText: 'View resources'
      }
    ],
    rolloutChecked: [],
    resourcesChecked: [],
    loading: true,
    error: null,
    exceedsSmallBreakpoint: false,
    exceedsMediumBreakpoint: false,
    exceedsLargeBreakpoint: false,
    subscriptionHashID: ''
  }

  mounted = false
  controller = new AbortController()

  componentDidMount () {
    BreakpointWatcher.addComponent(this)
    this.mounted = true
    const { appKey, history } = this.props
    const signal = this.controller.signal
    storage.token.get(appKey).then(token => {
      api.profile.get(token).then(profile => {
        this.handleApiError(profile)
        const roles = checkRole(profile.roles)
        if (!roles.includes('bp')) {
          history.push('/profile')
          return false
        }
        api.organisation.get(token).then(organisation => {
          this.handleApiError(organisation)
          this.mounted && this.setState({
            tier: Number(organisation.tier),
            numVehicles: organisation.numberOfVehicles,
            currentPlanMaxUsers: organisation.currentPlanMaxUsers,
            superOptions: reSuperOptions(organisation.tier, getSuperOptions(roles.includes('su'))),
            subscriptionHashID: organisation.subscriptionHashID
          })
        })
      })
      api.offers.get(token, signal, `offset=0&limit=${TEMP_MAX_ITEMS}&external=false`).then(offers => {
        if (offers === undefined) return false
        this.mounted && this.setState({
          numOffers: offers.totalCount
        })
      })
      api.rollOuts.get(token).then(item => {
        if ((item && item.code && item.code !== 200) || !item) return false // no access
        this.mounted && this.setState(state => {
          Object.entries(item).map(i => {
            if (i[1]) state.rolloutChecked.push(i[0])
            return false
          })
          return state
        })
      })
      api.resources.get(token).then(item => {
        if (!item) return false
        this.mounted && this.setState(state => {
          Object.entries(item).map(i => {
            if (i[1]) state.resourcesChecked.push(i[0])
            return false
          })
          state.loading = false
          return state
        })
      })
    })
  }

  componentWillUnmount () {
    BreakpointWatcher.removeComponent(this)
    this.mounted = false
    this.controller.abort()
  }

  handleRolloutCheck = value => {
    const { rolloutChecked, rollout } = this.state
    const hasValue = rolloutChecked.includes(value)
    this.mounted && this.setState(state => {
      if (hasValue) {
        state.rolloutChecked = rolloutChecked.filter(i => i !== value)
      } else {
        state.rolloutChecked.push(value)
      }
      return state
    }, () => {
      const rollouts = {}
      rollout.forEach(i => {
        rollouts[i.value] = this.state.rolloutChecked.includes(i.value)
      })
      storage.token.get().then(token => {
        api.rollOuts.update(token, rollouts)
      })
    })
  }

  handleResourcesCheck = value => {
    const { resourcesChecked, resources } = this.state
    const hasValue = resourcesChecked.includes(value)
    this.mounted && this.setState(state => {
      if (hasValue) {
        state.resourcesChecked = resourcesChecked.filter(i => i !== value)
      } else {
        state.resourcesChecked.push(value)
      }
      return state
    }, () => {
      const resource = {}
      resources.forEach(i => {
        resource[i.value] = this.state.resourcesChecked.includes(i.value)
      })
      storage.token.get().then(token => {
        api.resources.update(token, resource)
      })
    })
  }

  render () {
    const { history } = this.props
    const {
      tier,
      superOptions,
      rollout,
      resources,
      rolloutChecked,
      resourcesChecked,
      numVehicles,
      currentPlanMaxUsers,
      numOffers,
      error,
      exceedsMediumBreakpoint,
      exceedsLargeBreakpoint,
      subscriptionHashID
    } = this.state

    const reportDownloadLinkStyle = { display: 'block' }

    const reportUrl = 'https://www.myboost.co.nz/ResRetriver/rep_MBBrp.aspx?AH=' + subscriptionHashID

    const openReportUrl = () => {
      window.open(reportUrl, '_blank', 'noopener')
    }

    return (
      <Layout
        title="Dashboard"
        optionsData={superOptions}
        isSuperLayout={true}
      >
        {error && <Notification>{error}</Notification>}
        {tier.length === 0 ? (
          <LoadingIndicator />
        ) : (
          <Wrapper>
            <Section exceedsMediumBreakpoint={exceedsMediumBreakpoint} tier={tier}>
              <SubSection exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                <Text style={styles.heading}>Boost status</Text>
                <SubSubSection exceedsMediumBreakpoint={exceedsMediumBreakpoint} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <DashboardPane>
                    <PaneHero>
                      <InnerCircleWrap exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                        <View>
                          <Circle exceedsMediumBreakpoint={exceedsMediumBreakpoint} amount={numVehicles} max={currentPlanMaxUsers} />
                        </View>
                      </InnerCircleWrap>
                    </PaneHero>
                    <StatusSection>
                      {tier > 1 && <StatusText exceedsMediumBreakpoint={exceedsMediumBreakpoint}>
                        Need more users? <Text style={styles.link}
                          onPress={() => history.push('/profile/user-management-and-ordering/add-licenses')}>Add
                          more licenses</Text></StatusText>}
                    </StatusSection>
                  </DashboardPane>
                  {tier >= 2 && numOffers &&
                    <DashboardPane>
                      <PaneHero>
                        <OffersNumber exceedsMediumBreakpoint={exceedsMediumBreakpoint}>{numOffers}</OffersNumber>
                        <OffersText numOffers={numOffers} exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                          exceedsLargeBreakpoint={exceedsLargeBreakpoint} />
                      </PaneHero>
                      <SubHeaderLink exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                        onPress={() => history.push('/profile/offer-management')}
                      >
                        Manage Offers
                      </SubHeaderLink>
                    </DashboardPane>
                  }
                  <DashboardPane>
                    <PaneHero>
                      <a href={reportUrl} target="_blank" rel="noopener noreferrer">
                        <Image source={require('../../images/laptop_report.png')} style={reportIconStyle} />
                      </a>
                      <StatusText bold exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                        style={{ textAlign: 'center' }}>Reports
                      </StatusText>
                      <StatusSubText>Offer Snapshot</StatusSubText>
                    </PaneHero>
                    <SubHeaderLink exceedsMediumBreakpoint={exceedsMediumBreakpoint}
                      onPress={openReportUrl}
                      style={reportDownloadLinkStyle}>Download
                        Report</SubHeaderLink>
                  </DashboardPane>
                </SubSubSection>
              </SubSection>
            </Section>
            {tier >= 2 &&
              <List.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} isFirstWrapper>
                <Text style={styles.heading}>Roll-out Checklist</Text>
                <List.Text exceedsMediumBreakpoint={exceedsMediumBreakpoint}>Check these items off as you complete the roll-out of Boost to your staff. View the <Text
                  style={styles.link}
                  onPress={() => history.push('/profile/resources')}>Resources</Text> section for help.</List.Text>
                <List.Checks exceedsLargeBreakpoint={exceedsLargeBreakpoint}>
                  <CheckField
                    items={rollout}
                    checked={rolloutChecked}
                    onChange={value => this.handleRolloutCheck(value)}
                    itemStyle={styles.fieldWrap}
                    baseStyle={styles.fieldRow}
                  />
                </List.Checks>
              </List.Wrapper>
            }

            <List.Wrapper exceedsMediumBreakpoint={exceedsMediumBreakpoint} isFirstWrapper={tier < 2}>
              <Text style={styles.heading}>Resources for Success</Text>
              <List.Text>Everything you need to know about Boost</List.Text>
              <List.Checks exceedsLargeBreakpoint={exceedsLargeBreakpoint}>
                <CheckField
                  items={resources}
                  checked={resourcesChecked}
                  onChange={value => this.handleResourcesCheck(value)}
                  itemStyle={styles.fieldWrap}
                  baseStyle={styles.fieldRow}
                />
              </List.Checks>
            </List.Wrapper>
          </Wrapper>
        )}
      </Layout>
    )
  }
}
